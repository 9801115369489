import React from 'react';
import { useIsSsr } from './useIsSsr';

export const useGetScreenWidth = () => {
    const isSsr = useIsSsr();
    let width = isSsr ? null : window.innerWidth;
    const [isMobile, setIsMobile] = React.useState(false);

    const handleWindowSizeChange = () => {
        width = window.innerWidth;
        setIsMobile(width <= 768);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('deviceorientation', handleWindowSizeChange, true);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('deviceorientation', handleWindowSizeChange);
        };
    }, [handleWindowSizeChange]);

    React.useEffect(() => {
        if (!isSsr) {
            setIsMobile(width <= 768);
        }
    }, [isSsr, width]);

    return isMobile;
};
