import React, { FC } from 'react';
import './SmallCompanyContainer.scss';
import { ISmallCompanyProps } from '../../../../types/types';

const SmallCompanyContainer: FC<ISmallCompanyProps> = (props) => {
    return (
        <div
            data-testid="small-company-container"
            className={`small-company-container__wrapper small-company-container__wrapper--${props.marginValue}`}
        >
            <div
                className={`small-company-container small-company-container--${props.type} small-company-container--${props.size} small-company-container--${props.subsize}`}
            >
                <div className="small-company-container__header">
                    <img
                        className={`small-company-container__logo small-company-container__logo--${props.logoType}`}
                        src={props.img}
                        alt="Walmart"
                    />
                    <div className="small-company-container__time">
                        <span className="small-company-container__date">{props.date}</span>
                        <span className="small-company-container__readtime">{props.readtime}</span>
                    </div>
                </div>
                <p className="small-company-container__desc">{props.description}</p>
            </div>
            <div className={`small-company-container__footer small-company-container__footer--${props.footerType}`}>
                <p
                    className={`small-company-container__hashtags small-company-container__hashtags--${props.hide}`}
                >
                    <span
                        className={`small-company-container__label small-company-container__label--${props.labelType}`}
                    >
                        {props.label}
                    </span>

                    {props.hashtags}
                </p>
            </div>
        </div>
    );
};

export default SmallCompanyContainer;
