import React from 'react';
import { Link } from 'gatsby';
import './Technologies.scss';
import Button from '../../../common/Button';
import SectionTitle from '../../../common/SectionTitle';
import arrowRight from '../../../../assets/img/arr-right.svg';
import TechIcon from '../TechIcon/TechIcon';
import { FaJava } from 'react-icons/fa';
import { SiPython, SiDocker, SiAmazon } from 'react-icons/si';
import { DiJavascript1, DiRuby } from 'react-icons/di';
import { TTectIcons } from '../../../../types/types';

interface ITechnologies {
    showIcons: boolean;
}
const Technologies: React.FC<ITechnologies> = (props) => {
    const tectIcons: TTectIcons = [
        { id: 0, icon: <FaJava />, link: 'https://www.java.com/' },
        {
            id: 1,
            icon: <DiJavascript1 />,
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
        },
        { id: 2, icon: <DiRuby />, link: 'https://www.ruby-lang.org/en/' },
        { id: 3, icon: <SiPython />, link: 'python.org' },
        { id: 4, icon: <SiDocker />, link: 'https://www.docker.com/' },
        { id: 5, icon: <SiAmazon />, link: 'https://aws.amazon.com/' },
    ];

    return (
        <div data-testid="technologies" className="tech">
            <div className="tech__info">
                <SectionTitle
                    type="relevant"
                    title="Cannot find relevant technology stack or similar project from your industry?"
                />
                <p className="tech__desc">
                    It must be our rigorous
                    <Link to="/how-we-work/intellectual-property/">
                        <span className="tech__protection">
                            intellectual property protection effort.
                        </span>
                    </Link>
                    Just tell us what you need and get that information in less than 24 hours.
                </p>
                <Link className="link" to="/contact-us">
                    <Button icon={arrowRight} type="primary" desc="Tell us">
                        Tell us
                    </Button>
                </Link>
            </div>
            {props.showIcons && (
                <div className="tech__images">
                    <div className="tech__images-wrapper">
                        <TechIcon iconsArray={tectIcons} type="main" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Technologies;
