import React, { FC } from 'react';
import './Features.scss';
import SectionTitle from '../../../common/SectionTitle';
import Button from '../../../common/Button';
import { Link } from 'gatsby';
import { IFeaturesProps } from '../../../../types/types';

const Features: FC<IFeaturesProps> = (props) => {
    return (
        <div
            data-testid="features"
            className={`features__wrapper features__wrapper--${props.showBG}`}
        >
            <div className="features__container">
                <SectionTitle type="secondary" title={props.title} />
                {props.globalLink && (
                    <a href={props.link} target="_blank">
                        <Button
                            type="secondary"
                            subtype="mt"
                            desc="Discover all"
                            icon={props.buttonIcon}
                        >
                            {props.buttonText}
                        </Button>
                    </a>
                )}

                {props.localLink && (
                    <Link to="/how-we-work/" className="link">
                        <Button
                            type="secondary"
                            subtype="mt"
                            desc="Discover all"
                            icon={props.buttonIcon}
                        >
                            {props.buttonText}
                        </Button>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Features;
