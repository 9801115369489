import React, { FC } from 'react';
import './LargeCompanyContainer.scss';
import { ILargeCompanyProps } from '../../../../types/types';

const LargeCompanyContainer: FC<ILargeCompanyProps> = (props) => {
    return (
        <div data-testid="large-company-container" className="large-company-container__wrapper">
            <button
                className={`large-company-container large-company-container--${props.typeBG} large-company-container--${props.size} large-company-container--${props.type}`}
            >
                <div className="large-company-container__header">
                    <img
                        className={`large-company-container__logo large-company-container__logo--${props.logoPos}`}
                        src={props.img}
                        alt="Spectar"
                    />
                    <div className="large-company-container__time">
                        <span
                            className={`large-company-container__date large-company-container__date-${props.color}`}
                        >
                            {props.date}
                        </span>
                        <span
                            className={`large-company-container__readtime large-company-container__readtime-${props.color}`}
                        >
                            {props.readtime}
                        </span>
                    </div>
                </div>
                <p
                    className={`large-company-container__desc large-company-container__desc--${props.textStyle} large-company-container__desc-${props.color}`}
                >
                    {props.description}
                    {props.author && (
                        <span className="large-company-container__author">{props.author}</span>
                    )}
                </p>
            </button>
            <p
                className={`large-company-container__hashtags large-company-container__hashtags--${props.hide} large-company-container__hashtags--${props.hashtagsStyle}`}
            >
                <span
                    className={`large-company-container__label large-company-container__label--${props.labelType}`}
                >
                    {props.label}
                </span>
                {props.hashtags}
            </p>
        </div>
    );
};

export default LargeCompanyContainer;
