import React, { FC } from 'react';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import CookiesPopup from '../components/Cookies';
import Title from '../components/pagesComponents/MainPage/Title';
import Services from '../components/pagesComponents/MainPage/Services';
import Technologies from '../components/pagesComponents/MainPage/Technologies';
import Features from '../components/pagesComponents/MainPage/Features';
import Video from '../components/pagesComponents/MainPage/Video';
import AnimatedVideo from '../components/common/AnimatedVideo';
import TextSpan from '../components/pagesComponents/MainPage/TextSpan';
import '../styles/index.scss';
import arrowRight from '../assets/img/arr-right.svg';
import Calendar from '../assets/img/calendar.svg';
import spectarBlack from '../assets/img/spectar-black.svg';
import FixedButton from '../components/common/FixedButton';

const HomePage: FC = () => (
    <Layout>
        <SEO
            pageTitle="Blackbird Lab"
            pageDescription="Blackbird Lab is a software development company that provides effective solutions to clients worldwide. Since 2018, we have been on a mission to deliver quality products..."
            pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/main-page/main-page.png"
        />
        <main className="main">
            <div className="main__wrapper">
                <Title />
                <Services />
                <Technologies showIcons={true} />
                <Features
                    localLink={true}
                    globalLink={false}
                    buttonIcon={arrowRight}
                    title="More details on our service delivery and how we set up to success"
                    buttonText="Discover"
                />
                {/*<Video*/}
                {/*    logo={spectarBlack}*/}
                {/*    slogan="“I could probably go into sales for you. It's incredible. Thanks for the great service.”"*/}
                {/*    position="Anthony, CTO of Spectar.io"*/}
                {/*/>*/}
                <AnimatedVideo logo={spectarBlack} />
            </div>
            <TextSpan
                buttonLink="https://calendly.com/ydrozd"
                buttonText="Book a meeting"
                title="Let’s work together"
                buttonIcon={Calendar}
            >
                Even a 5-minute intro call could potentially save you countless hours of endless
                back-and-forth emails.
            </TextSpan>
        </main>
        <CookiesPopup />
        <FixedButton />
    </Layout>
);

export default HomePage;
