import React, { FC } from 'react';
import './TextSpan.scss';
import SectionTitle from '../../../common/SectionTitle';
import Button from '../../../common/Button';
import { ITextSpan } from '../../../../types/types';

const TextSpan: FC<ITextSpan> = (props) => {
    return (
        <section data-testid="text-span" className={`text-span text-span--${props.type}`}>
            <div className="text-span__wrapper">
                <SectionTitle type="secondary" title={props.title} />
                <p className="text-span__info">{props.children}</p>
                <a target="_blank" href={props.buttonLink}>
                    <Button
                        type="secondary"
                        desc="Discover all"
                        icon={props.buttonIcon}
                        iconType={props.iconType}
                    >
                        {props.buttonText}
                    </Button>
                </a>
            </div>
        </section>
    );
};

export default TextSpan;
