import React, { FC } from 'react';
import './TechIcon.scss';
import Bucket from '../../WhatWeDo/Bucket';
import bucketBGRectangle from '../../../../assets/img/companies/rectangle-bucket-bg.svg';
import { ITechIcons } from '../../../../types/types';

const TechIcon: FC<ITechIcons> = (props) => {
    return (
        <div
            data-testid="tech-icon"
            className={`tech__img-wrapper tech__img-wrapper--${props.type}`}
        >
            {props.iconsArray.map((icon) => (
                <a key={icon.id} href={icon.link} target="_blank">
                    <span className={`tech__img tech__img--${props.large}`}>{icon.icon}</span>
                </a>
            ))}
            {/* {props.showBucket && (
                <Bucket
                    bucketFunc={props.bucketFunc}
                    bucketBG={props.img}
                    label={props.label}
                    hide={props.show}
                    marginBG={props.margin}
                    size={props.size}
                    hideIMG={props.hideIMG}
                />
            )} */}
        </div>
    );
};

export default TechIcon;
